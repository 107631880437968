import { Component } from "react";
import mkpic from '../img/Portada.jpg';

class Home extends Component {

    render() {
        return(
            <div id={this.props.id} className="home">                
                <img src={mkpic} width = '100%' alt="About MK" />
                <h2>{this.props.langData.get('hello')}</h2>
                <div style={{maxWidth:'400px', marginLeft:'auto', marginRight:'auto'}}>
                    <p>{this.props.langData.get('hmkp1')} <b>{this.props.langData.get('hmkp2')}</b>.</p>
                    <p>{this.props.langData.get('hmkp3')} <i>{this.props.langData.get('hmkp4')}</i></p>
                </div>
            </div>
        )
    }
}

export default Home;