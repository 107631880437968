import { Component } from "react";
import { ImageMap } from '@qiuz/react-image-map';
import cfg from '../data/pdata.json';
import contact from '../img/Contact.png';

const mapArea = [
    {
      left: '46.5%',
      top: '54.1%',
      height: '6%',
      width: '6%',
      style: {cursor: 'pointer'}
    },
    {
      left: '61.5%',
      top: '54.1%',
      width: '7%',
      height: '6%',
      style: {cursor: 'pointer'}
    },
    {
      left: '78.2%',
      top: '54.1%',
      width: '5%',
      height: '6%',
      style: {cursor: 'pointer'}
    }
  ];


class MkContact extends Component {

    onMapClick(area, index) {
        switch (index) {
            case 0:                
                window.open("tel:" + cfg.call, "_self");                
                break; 
            case 1:
                window.open("mailto:" + cfg.email , "_self");
                break;
            case 2:
                window.open(cfg.wsp.contact, "_blank");
                break;
            case 3:
                window.open(cfg.instg, "_blank");
                break;
            case 4:
                window.open(cfg.tiktok, "_blank");
                break;       
            default:
                alert('This function is under implementation!');
                break;
        }
    }

    render() {
        return(
            <div id={this.props.id} className="contact">
                <h1>{this.props.langData.get('cttit')}</h1>
                <p>{this.props.langData.get('ctp1')}</p>
                <ImageMap
                    src={contact}
                    map={mapArea}
                    onMapClick={this.onMapClick}
                />
                <p>{this.props.langData.get('ctp2')}</p>
            </div>
        )
    }

}

export default MkContact;