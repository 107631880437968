import { Component } from "react";
import ctdb from '../data/content_db.json';

const images = importAll(require.context('../img/services/', false, /\.(png|jpe?g|svg)$/));

function importAll(rc) {
    return rc.keys().map(rc);
}

class MkSrv extends Component {

    render() {
        return(          
            <div id={this.props.id} className="srv">
                <h1>{this.props.langData.get('srv')}</h1>
                {ctdb.services.map((item, id) =>(
                    <article key={id}>
                        <img src={images[item.idpict]} alt="Services" width='100%' />
                        <h2>{this.props.langData.get(item.title)}</h2>
                        <p>{this.props.langData.get(item.desc)}</p>
                    </article>
                ))}
            </div>
        )
    }
}

export default MkSrv;