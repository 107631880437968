import { Component } from "react";
import cfg from '../data/pdata.json';
import image1 from '../img/Smc.jpg';

class MkSmc extends Component {

    render() {
        return(
            <div id={this.props.id} className="smc">
                <h1>{this.props.langData.get('smctit')}</h1>
                <img src={image1} alt="smc" width='100%' />
                <p>{this.props.langData.get('smcpq')}</p>
                <p>{this.props.langData.get('smcp')}</p>
                <button onClick={this.onBtnClick}>{this.props.langData.get('smcbtn')}</button>
            </div> 
        )
    }

    onBtnClick(ev) {
        ev.preventDefault();
        window.open(cfg.wsp.smc, "_blank");
    }

}

export default MkSmc;