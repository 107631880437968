import React, {useEffect, useRef, useState } from 'react';
import Navigation from '../navigation/Navigation';
import LeftPane from '../navigation/LeftPane2';
import RightPane from '../navigation/RightPane';
import lang_en from '../../data/en.json';
import lang_es from '../../data/es.json';
import MkRouting2 from '../routing/MkRouting2';
import Utils from '../../utils/utils';

const Layout2 = ({ children }) => {
    const [lang, setLang] = useState({lang: 'en', langMap: new Map()});
    const panelsEv = useRef({doOpenLeft: null, doOpenRight: null, doCloseLeft: null, doCloseRight: null});

    useEffect(() => {   
        doTranslation(lang.lang);
    }, [lang.lang]);

    let acceptDoLeftPane = (doOpenL) => {
        // Parent stores the method that the child passed
        panelsEv.current.doOpenLeft = doOpenL;
    }
    
    let acceptDoRightPane = (doOpenR) => {
        // Parent stores the method that the child passed
        panelsEv.current.doOpenRight = doOpenR;
    }
    
    let acceptDoCloseLPane = (doCloseL) => {
        // Parent stores the method that the child passed
        panelsEv.current.doCloseLeft = doCloseL;
    }
    
    let acceptDoCloseRPane = (doCloseR) => {
        // Parent stores the method that the child passed
        panelsEv.current.doCloseRight = doCloseR;
    }
    
    let onPanel = (ev) => {
        if(ev === 'L') {
            panelsEv.current?.doOpenLeft(ev); 
            panelsEv.current?.doCloseRight();          
        } else {
            panelsEv.current?.doOpenRight(ev);
            panelsEv.current?.doCloseLeft();
        }    
    } 
  
    let onRightItemClick = (ev) => {          
        if (ev !== lang.lang) {
            doTranslation(ev);
            setLang((prevState) => ({ ...prevState, lang: ev }));
        }        
    }

    let onLeftItemClick = (ev) => {          
        if(ev === '/srv') Utils.gotoItem('top'); 
         
    }

    let doTranslation = (selectedLang) => {
        let newLangMap = new Map();
        let langData = selectedLang === 'es' ? lang_es : lang_en;
        langData.forEach(item => { newLangMap.set(item.key, item.value); });
        setLang((prevState) => ({ ...prevState, langMap: newLangMap }));
    }

    return (
        <div className='globlayout'>
            <header id='top'>
                <Navigation onOpenPane={onPanel}/>
                <LeftPane langData={lang.langMap} openPane={acceptDoLeftPane} closePane={acceptDoCloseLPane} onItemClick={onLeftItemClick}/>
                <RightPane langData={lang.langMap} openPane={acceptDoRightPane} closePane={acceptDoCloseRPane} onItemClick={onRightItemClick}/>
                <span>&nbsp;</span>
            </header>
            <main className="content">                 
                <MkRouting2 langData={lang.langMap} />        
            </main>
        </div>
    );

};

export default Layout2;