import { useEffect } from "react";
import Home from "./Home";
import MkAbout from "./MkAbout";
import MkContact from "./MkContact";
import MkPortf from "./MkPortf";
import Testimonials from "./Testimonials";
import { useParams } from "react-router-dom";
import Utils from "../utils/utils";

function Home2(props) {
    const {ilnk} = useParams();

    useEffect(() => {   
        Utils.gotoItem('top');
        setTimeout(Utils.gotoItem(ilnk? ilnk : 'top'), 540);
    }, [ilnk]);

    return (
        <div className="home2">
            <Home langData={props.langData} id="home" /> 
            <hr/>
            <MkAbout langData={props.langData} id="about" />
            <hr/>
            <MkPortf langData={props.langData} id="portf" /> 
            <hr/>
            <Testimonials langData={props.langData} id="testmn" />
            <hr/>
            <MkContact langData={props.langData} id="contact" />
        </div>
    );
}

export default Home2;