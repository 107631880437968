class Utils {

    gotoItem(id) {  
        let item = document.getElementById(id);
        item.scrollIntoView({ behavior: 'smooth' });
    }

}

Utils = new Utils();

export default Utils;