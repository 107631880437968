import { Component } from "react"
import { Route, Routes } from "react-router-dom"
import MkSrv from "../MkSrv"
import MkSmc from "../MkSmc"
import Home2 from "../Home2"

class MkRouting2 extends Component {

    render() {
        return(            
            <Routes>   
                <Route path="/" element={<Home2 langData={this.props.langData} />} />
                <Route path="/:ilnk" element={<Home2 langData={this.props.langData} />} />                     
                <Route path="srv" element={<MkSrv langData={this.props.langData} />} />
                <Route path="smc" element={<MkSmc langData={this.props.langData} />} />    
            </Routes> 
        )
    }
}

export default MkRouting2