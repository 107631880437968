import MainComp from './components/layouts/Layout2.0';

function App() {

  return(
    <div className="App">
      <MainComp />
    </div>
  );
}

export default App;
