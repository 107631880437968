import { Component, createRef } from "react";

class RightPane extends Component {

    constructor() {
      super();
      this.Rsidenav = createRef();
      this.doItemClick = this.doItemClick.bind(this);
    }

    componentDidMount() {
      // Child passes its method to the parent
      this.props.openPane(this.doOpenPane.bind(this));    
      this.props.closePane(this.doClosePane.bind(this)); 
    }

    doOpenPane(ev) {
      if(this.Rsidenav.current) {
        /* let nvb = document.getElementById('mk_nvb');
        this.Rsidenav.current.style.left = (nvb.getBoundingClientRect().right - 150) + 'px'; */
        this.Rsidenav.current.style.width = '150px';
      }
    }


    doClosePane() {
        if(this.Rsidenav.current)
          this.Rsidenav.current.style.width = '0px';
    }

    doItemClick(ev) {
        ev.preventDefault();
        this.doClosePane();
        if(!ev.target.lang) return;
        this.props.onItemClick(ev.target.lang);
    }

    render() {
        return(
          <div ref={this.Rsidenav} className="rightnav">
            <a href="close" className="closebtn" onClick={this.doItemClick}>&times;</a>
            <a href="en" lang="en" onClick={this.doItemClick}>{this.props.langData.get('en')}</a><hr/>			
            <a href="es" lang="es" onClick={this.doItemClick}>{this.props.langData.get('es')}</a>
          </div>
        )
    }

}

export default RightPane;