import tdata from '../data/content_db.json';

const Testimonials = (props) => {

    let getStars = (count) => {
        //10025
        return (
            count === 1? <span>&#9733;&#10025;&#10025;&#10025;&#10025;</span> : 
            count === 2? <span>&#9733;&#9733;&#10025;&#10025;&#10025;</span> :
            count === 3? <span>&#9733;&#9733;&#9733;&#10025;&#10025;</span> :
            count === 4? <span>&#9733;&#9733;&#9733;&#9733;&#10025;</span> : <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
        );
    }

    return(
        <div id={props.id} className='testm'>
            <h1>{props.langData.get('testmn')}</h1>
            {tdata.testimonials.map((item, id) =>(
                <article key={id}>
                    {getStars(item.stars)}
                    <p><b>{item.name}</b></p> 
                    <p>{item.title}</p>
                    <p>{item.desc}</p>
                </article>
            ))}
        </div>
    );

}

export default Testimonials;