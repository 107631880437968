import React, { Component } from "react";
import pfimg from '../img/PortFolio.jpg';

class MkPortf extends Component {

    constructor() {
        super()
        this.state ={
            currentIndex:0
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(index) {
        this.setState({currentIndex:index})
    }

    render() {

        return (
            <div id={this.props.id} className="fade_content">
                <h1>&nbsp;{/* {this.props.langData.get('portf')} */}</h1>
                <img src={pfimg} alt="Portfolio" width='90%'/>
            </div> 
        )
    }
}

export default MkPortf;