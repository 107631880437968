import { Component, createRef } from "react";
import { Link } from "react-router-dom";

class LeftPane2 extends Component {

    constructor() {
        super();
        this.Lsidenav = createRef();
        this.doItemClick = this.doItemClick.bind(this);
    }

    componentDidMount() {
        // Child passes its method to the parent
        this.props.openPane(this.doOpenPane.bind(this));  
        this.props.closePane(this.doclosePane.bind(this));
     }

      doOpenPane(ev) {
        if(this.Lsidenav.current)
          this.Lsidenav.current.style.width = '270px';
      }

      doclosePane() {
        if(this.Lsidenav.current)
          this.Lsidenav.current.style.width = '0px';
      }

      doItemClick(ev) {
        this.doclosePane();
        if(ev.target.id === 'lp_close' || ev.target.id === 'srvcs') {
          ev.preventDefault();
          return;
        }
        if(this.props.onItemClick) this.props.onItemClick(ev.target.pathname);
      }

    render() {  
      return(
        <div ref={this.Lsidenav} className="sidenav">
          <a href="close" id="lp_close" className="closebtn" onClick={this.doItemClick}>&times;</a>
          <ul>
            <li><Link to="/top" onClick={this.doItemClick}>{this.props.langData.get('home')}</Link><hr/></li>
            <li><Link to="/about" onClick={this.doItemClick}>{this.props.langData.get('amk')}</Link><hr/></li>
            <li><Link to="/portf" onClick={this.doItemClick}>{this.props.langData.get('portf')}</Link><hr/></li>
            <li><Link to="/testmn"  onClick={this.doItemClick}>{this.props.langData.get('testmn')}</Link><hr/></li>
            <li><a href="services" id="srvcs" onClick={this.doItemClick}>{this.props.langData.get('srvg')}</a></li>
            <li>
              <ul>
                <li><Link to="srv" onClick={this.doItemClick}>{this.props.langData.get('srv')}</Link><hr/></li>
                <li><Link to="smc" onClick={this.doItemClick}>{this.props.langData.get('smc')}</Link></li>
              </ul><hr/>
            </li>
            <li><Link to="/contact" onClick={this.doItemClick}>{this.props.langData.get('contact')}</Link></li>
          </ul>
			  </div>
      )
    }
}

export default LeftPane2;